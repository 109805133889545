.card {
     position: relative;
     padding: .75rem;
     width: 220px;
     height: max-content;
     display: flex;
     white-space: nowrap;
     align-items: center;
     border-radius: 0px 25px;
     flex-direction: column;
     justify-content: center;
     border: 1px solid var(--clr-light-green);
     background: var(--clr-black-shade-bg);
}

.card-heading {
     font-size: 1.1rem;
     color: var(--clr-light-green);
}

.card-value {
     font-size: 1.3rem;
     color: white;
}

@media only screen and (max-width:998px) {

     .card {
          width: 170px;
     }

     .card-heading {
          font-size: .8rem;
     }

     .card-value {
          font-size: .9rem;
     }

}

@media only screen and (max-width:425px) {

     .card {
          width: 150px;
     }

     .card-heading {
          font-size: .8rem;
     }

     .card-value {
          font-size: .9rem;
     }

}