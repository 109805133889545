@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');

*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Russo One', sans-serif;
}

:root {
  --nav-height: 4rem;
  --clr-light-green: #A3FF12;
  --clr-black-shade-bg: #0D0D0D;
}


.clr-green {
  color: var(--clr-light-green);
}

.clr-white {
  color: #fff;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  -moz-appearance: textfield;
}