.footer {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  height: var(--nav-height);
  justify-content: space-between;
  background: var(--clr-black-shade-bg);
  width: 100%;
  zoom: 0.8;

}

.footer .logo-text {
  font-weight: 400;
  font-size: 1.1rem;
}

.copyright {
  opacity: 0.7;
  color: white;
  font-size: 0.9rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.social-links {
  gap: 1rem;
  display: flex;
  color: var(--clr-light-green);
  cursor: pointer;
}



@media screen and (min-width: 998px) {
.footer{
  position: fixed;
  bottom: 0;
}

}


@media screen and (max-width: 668px) {
  .footer {
    display: grid;
    padding: 0.25rem 0.5rem;
    font-size: 0.6rem;
    grid-template-columns: 1fr 1fr;
    justify-content: space-evenly;
  }

  .footer .logo-text {
    font-size: 0.8rem;
    grid-column: 1 / 2;
    letter-spacing: 1px;
    text-align: left;
  }

  .copyright {
    font-size: 0.6rem;
    grid-column: 1 / 3;
    text-align: center;
    padding: 0.25rem 0;
    border-top: 1px solid var(--clr-light-green);
  }

  .social-links {
    gap: 0.75rem;
    font-size: 1rem;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    justify-content: flex-end;
  }
}
