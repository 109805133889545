.header {
     top: 0;
     left: 0;
     width: 100%;
     z-index: 99;
     position: sticky;
     background: #000;
}

.nav {
     width: 95%;
     margin: auto;
     display: flex;
     padding: 1rem;
     align-items: center;
     height: var(--nav-height);
     justify-content: space-between;
}

.logo {
     gap: 1rem;
     display: flex;
     align-items: center;
     cursor: pointer;
}

.logo-image {
     width: 45px;
}

.logo-text {
     color: var(--clr-light-green);
     text-align: center;
     font-family: Russo One;
     font-size: 1.5rem;
     font-style: normal;
     font-weight: 400;
     line-height: 29px;
     letter-spacing: 5.95px;
     text-transform: uppercase;
}

.menu {
     gap: 1rem;
     display: flex;
     align-items: center;
     color: var(--clr-light-green);
}

.menu button {
     cursor: pointer !important;
     color: black !important;
     border-radius: 0px 20px !important;
     border: 1px solid rgba(255, 255, 255, 0.10);
     background-color: var(--clr-light-green) !important;
}


.hamberger-menu,
.close-menu-btn {
     border: none;
     cursor: pointer;
     width: max-content;
     align-items: center;
     height: min-content;
     background: transparent;
     outline: var(--clr-light-green);
}


.hamberger-menu {
     display: none;
     font-size: 1.8rem;
     color: var(--clr-light-green);
}

#hamberger-checkbox {
     opacity: 0;
     appearance: none;
}

.menu-option {
     display: none;
     top: 100%;
     width: 140px;
     height: 90px;
     padding: 1.4rem;
     position: absolute;
     border-radius: 20px;
     background: #000;
     align-items: flex-end;
}

.menu-option .close-menu-btn {
     top: 10px;
     right: 10px;
     position: absolute;
     font-size: 1.5rem;
     color: var(--clr-light-green);
}


.left-nav:has(#hamberger-checkbox:checked) .menu-option {
     display: flex;
}


@media screen and (max-width:668px) {
     .nav {
          width: 100%;
     }

     .left-nav {
          display: flex;
          align-items: center;
     }

     .logo {
          gap: .5rem;
     }

     .logo-image {
          width: 30px;
     }

     .logo-text {
          letter-spacing: 1px;
          font-size: 1rem;
     }

     .home {
          display: none;
     }

     .menu {
          font-size: .9rem !important;
     }

     .hamberger-menu {
          display: flex;
     }


}

@media screen and (max-width:425px) {
     .nav {
          padding: .5rem;
     }


     .logo {
          gap: 0;
     }

     .logo-text {
          font-size: .9rem;
          white-space: nowrap;
          line-height: normal;
          letter-spacing: 1px;
     }

     .menu button {
          white-space: nowrap !important;
          height: max-content !important;
          padding: .5rem !important;
          border-radius: 0px 15px !important;
          border: 1px solid rgba(255, 255, 255, 0.10);
          background-color: var(--clr-light-green) !important;
     }

}