@import url(https://fonts.googleapis.com/css2?family=Russo+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Russo One', sans-serif;
}

:root {
  --nav-height: 4rem;
  --clr-light-green: #A3FF12;
  --clr-black-shade-bg: #0D0D0D;
}


.clr-green {
  color: #A3FF12;
  color: var(--clr-light-green);
}

.clr-white {
  color: #fff;
}

.letter-spacing-1 {
  letter-spacing: 1px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input {
  -moz-appearance: textfield;
}
.card {
     position: relative;
     padding: .75rem;
     width: 220px;
     height: -webkit-max-content;
     height: max-content;
     display: flex;
     white-space: nowrap;
     align-items: center;
     border-radius: 0px 25px;
     flex-direction: column;
     justify-content: center;
     border: 1px solid var(--clr-light-green);
     background: var(--clr-black-shade-bg);
}

.card-heading {
     font-size: 1.1rem;
     color: var(--clr-light-green);
}

.card-value {
     font-size: 1.3rem;
     color: white;
}

@media only screen and (max-width:998px) {

     .card {
          width: 170px;
     }

     .card-heading {
          font-size: .8rem;
     }

     .card-value {
          font-size: .9rem;
     }

}

@media only screen and (max-width:425px) {

     .card {
          width: 150px;
     }

     .card-heading {
          font-size: .8rem;
     }

     .card-value {
          font-size: .9rem;
     }

}
.bg-image {
  inset: 0;
  z-index: -1;
  width: 100vw;
  /* height: 100vh; */
  position: fixed;
  background: url(/static/media/bg.9cb70960.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.amount {
  width: 90%;
  display: flex !important;
  margin: auto;
}

.amount > button{
  padding: 0px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .bg-image img {
     width: max-content;
     height: max-content;
} */

.loadingImgDiv {
  position: relative;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
}

.loadingImg {
  /* No changes needed for .loadingImg styles */
  position: fixed;
  z-index: 9999;
}

.rock-1 {
  top: 15%;
  left: 20%;
  width: 70px;
  position: fixed;
}

.rock-2 {
  top: 45%;
  left: 5%;
  width: 30px;
  position: fixed;
}

.rock-3 {
  top: 85%;
  left: 25%;
  width: 60px;
  position: fixed;
}

.rock-4 {
  top: 15%;
  right: 20%;
  width: 50px;
  position: fixed;
}

.rock-5 {
  top: 45%;
  right: 5%;
  width: 70px;
  position: fixed;
}

.rock-6 {
  top: 85%;
  right: 25%;
  width: 30px;
  position: fixed;
}

.planet-1 {
  top: 2rem;
  left: 0;
  width: 110px;
  position: fixed;
}

.planet-2 {
  right: 0;
  bottom: -1rem;
  width: 110px;
  position: fixed;
}

.main-container {
  width: 100%;
  display: flex;
  padding: 10rem 3rem 5rem 3rem;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  /* min-height: 100vh;      */
}

.token-container-wrapper {
  width: min(340px, 100%);
  height: auto;
  position: relative;
}

.upper-box-group {
  top: -100px;
  width: 140%;
  left: 50%;
  translate: -50% 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
}

.left-box-group,
.right-box-group {
  display: grid;
  --distance: -90%;
  position: relative;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  justify-content: center;
  align-items: center;
}

.left-box-group {
  left: var(--distance);
  height: 100%;
  position: absolute;
}

.right-box-group {
  height: 100%;
  right: var(--distance);
  position: absolute;
}

.left-box-group .card::before,
.right-box-group .card::before {
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  background: var(--clr-light-green);
}

.left-box-group .card::before {
  left: 100%;
}

.right-box-group .card::before {
  right: 100%;
}

.token-container {
  width: 100%;
  height: 100%;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  padding: 1.5em;
  display: flex;
  text-align: center;
  position: relative;
  align-items: center;
  border-radius: 0 40px;
  flex-direction: column;
  justify-content: space-evenly;
  background: var(--clr-black-shade-bg);
}

.comet-img {
  bottom: -52px;
  position: absolute;
}

.comet-img img {
  width: 90px;
}

.token-note {
  font-weight: 400;
  opacity: 0.7;
}

.pol-id {
  grid-gap: 0.5rem;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note {
  opacity: 0.7;
  font-size: 0.8rem;
}

.pol-creater-wrapper {
  width: 100%;
  height: auto;
  padding: 1rem 0rem;
  position: relative;
  border-radius: 0 40px;
  background: var(--clr-light-green);
  border: 1px solid var(--clr-black-shade-bg);
  margin-bottom: 2rem;
}

.payment-option-container {
  grid-gap: 1em;
  gap: 1em;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
}

.payment-option-container input {
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  border-radius: 1rem;
  background: transparent;
  border: 1px solid #000;
  font-size: 20px;
  font-family: Inter;
  color: rgba(18, 18, 18, 0.88);
}

.payment-option-container input::-webkit-input-placeholder {
  color: black;
  font-weight: 400;
}

.payment-option-container input::placeholder {
  color: black;
  font-weight: 400;
}

.payment-opt-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-opt-btn-group button {
  cursor: pointer;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  border-radius: 0 10px;
  color: var(--clr-light-green);
  background: var(--clr-black-shade-bg);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.connect-wallet-btn {
  /* margin-top: 10px; */
  justify-self: flex-end;
}

.connect-wallet-btn button {
  width: 100% !important;
  height: 100% !important;
  padding: 0.75rem 0 !important;
  text-align: center !important;
  border-radius: 0 20px !important;
  color: var(--clr-light-green) !important;
  background: var(--clr-black-shade-bg) !important;
}

.wrapper-container {
  top: 50%;
  left: 50%;
  width: 102%;
  height: 102%;
  display: flex;
  padding: 1.5rem;
  position: absolute;
  align-items: center;
  border-radius: 0 40px;
  justify-content: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border: 1px solid var(--clr-black-shade-bg);
}

.pol-creater {
  width: 100%;
  height: 100%;
  border-radius: 0 40px;
  background: var(--clr-black-shade-bg);
}

.pol-creater .container {
  grid-gap: 0.5rem;
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.pol-creater .container input {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border-radius: 0 30px;
  background: transparent;
  color: var(--clr-light-green);
  border: 1px solid var(--clr-light-green);
}

.pol-creater .container input::-webkit-input-placeholder {
  font-size: 0.6rem;
  text-align: center;
  color: var(--clr-light-green);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.pol-creater .container input::placeholder {
  font-size: 0.6rem;
  text-align: center;
  color: var(--clr-light-green);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.pol-creater .container .confirm-btn {
  padding: 0.5rem;
  font-size: 0.7rem;
  background: var(--clr-light-green);
}

@media only screen and (max-width: 998px) {
  .main-container {
    height: 100vh;
    /* margin-top: 15vh;
    margin-bottom: 10vh; */
    align-items: center;
  }

  .upper-box-group {
    grid-gap: 2rem;
    gap: 2rem;
    top: -250px;
    width: -webkit-max-content;
    width: max-content;
  }

  .left-box-group,
  .right-box-group {
    left: 50%;
    grid-gap: 2rem;
    gap: 2rem;
    --distance: -40%;
    height: -webkit-max-content;
    height: max-content;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    grid-template-columns: 1fr 1fr;
  }

  .left-box-group {
    top: var(--distance);
    position: absolute;
  }

  .right-box-group {
    bottom: var(--distance);
    position: absolute;
  }

  .left-box-group .card:nth-child(2),
  .right-box-group .card:nth-child(2) {
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }

  .left-box-group .card:nth-child(2) {
    top: 120%;
  }

  .right-box-group .card:nth-child(2) {
    bottom: 120%;
  }

  .left-box-group .card::before,
  .right-box-group .card::before {
    left: 50%;
    width: 2px;
    height: 120px;
    translate: -50% 0;
  }

  .left-box-group .card::before {
    top: 100%;
  }

  .right-box-group .card::before {
    bottom: 100%;
  }

  /* 
          *Main mid card resposiveness
     */

  .token-container {
    padding: 5%;
  }

  .payment-option-container {
    padding: 5% 7%;
  }
}

@media screen and (max-width: 768px) {
  .left-box-group,
  .right-box-group,
  .upper-box-group {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .main-container {
    height: 100vh;
    margin-top: 20vh;
    margin-bottom: 10vh;
    align-items: center;
    padding: 1.5rem;
  }

  .comet-img {
    bottom: -40px;
  }

  .comet-img img {
    width: 70px;
  }
}

.header {
     top: 0;
     left: 0;
     width: 100%;
     z-index: 99;
     position: -webkit-sticky;
     position: sticky;
     background: #000;
}

.nav {
     width: 95%;
     margin: auto;
     display: flex;
     padding: 1rem;
     align-items: center;
     height: var(--nav-height);
     justify-content: space-between;
}

.logo {
     grid-gap: 1rem;
     gap: 1rem;
     display: flex;
     align-items: center;
     cursor: pointer;
}

.logo-image {
     width: 45px;
}

.logo-text {
     color: var(--clr-light-green);
     text-align: center;
     font-family: Russo One;
     font-size: 1.5rem;
     font-style: normal;
     font-weight: 400;
     line-height: 29px;
     letter-spacing: 5.95px;
     text-transform: uppercase;
}

.menu {
     grid-gap: 1rem;
     gap: 1rem;
     display: flex;
     align-items: center;
     color: var(--clr-light-green);
}

.menu button {
     cursor: pointer !important;
     color: black !important;
     border-radius: 0px 20px !important;
     border: 1px solid rgba(255, 255, 255, 0.10);
     background-color: var(--clr-light-green) !important;
}


.hamberger-menu,
.close-menu-btn {
     border: none;
     cursor: pointer;
     width: -webkit-max-content;
     width: max-content;
     align-items: center;
     height: -webkit-min-content;
     height: min-content;
     background: transparent;
     outline: var(--clr-light-green);
}


.hamberger-menu {
     display: none;
     font-size: 1.8rem;
     color: var(--clr-light-green);
}

#hamberger-checkbox {
     opacity: 0;
     -webkit-appearance: none;
             appearance: none;
}

.menu-option {
     display: none;
     top: 100%;
     width: 140px;
     height: 90px;
     padding: 1.4rem;
     position: absolute;
     border-radius: 20px;
     background: #000;
     align-items: flex-end;
}

.menu-option .close-menu-btn {
     top: 10px;
     right: 10px;
     position: absolute;
     font-size: 1.5rem;
     color: var(--clr-light-green);
}


.left-nav:has(#hamberger-checkbox:checked) .menu-option {
     display: flex;
}


@media screen and (max-width:668px) {
     .nav {
          width: 100%;
     }

     .left-nav {
          display: flex;
          align-items: center;
     }

     .logo {
          grid-gap: .5rem;
          gap: .5rem;
     }

     .logo-image {
          width: 30px;
     }

     .logo-text {
          letter-spacing: 1px;
          font-size: 1rem;
     }

     .home {
          display: none;
     }

     .menu {
          font-size: .9rem !important;
     }

     .hamberger-menu {
          display: flex;
     }


}

@media screen and (max-width:425px) {
     .nav {
          padding: .5rem;
     }


     .logo {
          grid-gap: 0;
          gap: 0;
     }

     .logo-text {
          font-size: .9rem;
          white-space: nowrap;
          line-height: normal;
          letter-spacing: 1px;
     }

     .menu button {
          white-space: nowrap !important;
          height: -webkit-max-content !important;
          height: max-content !important;
          padding: .5rem !important;
          border-radius: 0px 15px !important;
          border: 1px solid rgba(255, 255, 255, 0.10);
          background-color: var(--clr-light-green) !important;
     }

}
.footer {
  padding: 0 2rem;
  display: flex;
  align-items: center;
  height: var(--nav-height);
  justify-content: space-between;
  background: var(--clr-black-shade-bg);
  width: 100%;
  zoom: 0.8;

}

.footer .logo-text {
  font-weight: 400;
  font-size: 1.1rem;
}

.copyright {
  opacity: 0.7;
  color: white;
  font-size: 0.9rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.social-links {
  grid-gap: 1rem;
  gap: 1rem;
  display: flex;
  color: var(--clr-light-green);
  cursor: pointer;
}



@media screen and (min-width: 998px) {
.footer{
  position: fixed;
  bottom: 0;
}

}


@media screen and (max-width: 668px) {
  .footer {
    display: grid;
    padding: 0.25rem 0.5rem;
    font-size: 0.6rem;
    grid-template-columns: 1fr 1fr;
    justify-content: space-evenly;
  }

  .footer .logo-text {
    font-size: 0.8rem;
    grid-column: 1 / 2;
    letter-spacing: 1px;
    text-align: left;
  }

  .copyright {
    font-size: 0.6rem;
    grid-column: 1 / 3;
    text-align: center;
    padding: 0.25rem 0;
    border-top: 1px solid var(--clr-light-green);
  }

  .social-links {
    grid-gap: 0.75rem;
    gap: 0.75rem;
    font-size: 1rem;
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    justify-content: flex-end;
  }
}

