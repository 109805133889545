.bg-image {
  inset: 0;
  z-index: -1;
  width: 100vw;
  /* height: 100vh; */
  position: fixed;
  background: url('../assets/images/bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.amount {
  width: 90%;
  display: flex !important;
  margin: auto;
}

.amount > button{
  padding: 0px;
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .bg-image img {
     width: max-content;
     height: max-content;
} */

.loadingImgDiv {
  position: relative;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
}

.loadingImg {
  /* No changes needed for .loadingImg styles */
  position: fixed;
  z-index: 9999;
}

.rock-1 {
  top: 15%;
  left: 20%;
  width: 70px;
  position: fixed;
}

.rock-2 {
  top: 45%;
  left: 5%;
  width: 30px;
  position: fixed;
}

.rock-3 {
  top: 85%;
  left: 25%;
  width: 60px;
  position: fixed;
}

.rock-4 {
  top: 15%;
  right: 20%;
  width: 50px;
  position: fixed;
}

.rock-5 {
  top: 45%;
  right: 5%;
  width: 70px;
  position: fixed;
}

.rock-6 {
  top: 85%;
  right: 25%;
  width: 30px;
  position: fixed;
}

.planet-1 {
  top: 2rem;
  left: 0;
  width: 110px;
  position: fixed;
}

.planet-2 {
  right: 0;
  bottom: -1rem;
  width: 110px;
  position: fixed;
}

.main-container {
  width: 100%;
  display: flex;
  padding: 10rem 3rem 5rem 3rem;
  position: relative;
  align-items: flex-end;
  justify-content: center;
  /* min-height: 100vh;      */
}

.token-container-wrapper {
  width: min(340px, 100%);
  height: auto;
  position: relative;
}

.upper-box-group {
  top: -100px;
  width: 140%;
  left: 50%;
  translate: -50% 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: space-between;
}

.left-box-group,
.right-box-group {
  display: grid;
  --distance: -90%;
  position: relative;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  justify-content: center;
  align-items: center;
}

.left-box-group {
  left: var(--distance);
  height: 100%;
  position: absolute;
}

.right-box-group {
  height: 100%;
  right: var(--distance);
  position: absolute;
}

.left-box-group .card::before,
.right-box-group .card::before {
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  background: var(--clr-light-green);
}

.left-box-group .card::before {
  left: 100%;
}

.right-box-group .card::before {
  right: 100%;
}

.token-container {
  width: 100%;
  height: 100%;
  gap: 0.75rem;
  padding: 1.5em;
  display: flex;
  text-align: center;
  position: relative;
  align-items: center;
  border-radius: 0 40px;
  flex-direction: column;
  justify-content: space-evenly;
  background: var(--clr-black-shade-bg);
}

.comet-img {
  bottom: -52px;
  position: absolute;
}

.comet-img img {
  width: 90px;
}

.token-note {
  font-weight: 400;
  opacity: 0.7;
}

.pol-id {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note {
  opacity: 0.7;
  font-size: 0.8rem;
}

.pol-creater-wrapper {
  width: 100%;
  height: auto;
  padding: 1rem 0rem;
  position: relative;
  border-radius: 0 40px;
  background: var(--clr-light-green);
  border: 1px solid var(--clr-black-shade-bg);
  margin-bottom: 2rem;
}

.payment-option-container {
  gap: 1em;
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
}

.payment-option-container input {
  width: 100%;
  padding: 0.5rem;
  text-align: center;
  border-radius: 1rem;
  background: transparent;
  border: 1px solid #000;
  font-size: 20px;
  font-family: Inter;
  color: rgba(18, 18, 18, 0.88);
}

.payment-option-container input::placeholder {
  color: black;
  font-weight: 400;
}

.payment-opt-btn-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-opt-btn-group button {
  cursor: pointer;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
  border-radius: 0 10px;
  color: var(--clr-light-green);
  background: var(--clr-black-shade-bg);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.connect-wallet-btn {
  /* margin-top: 10px; */
  justify-self: flex-end;
}

.connect-wallet-btn button {
  width: 100% !important;
  height: 100% !important;
  padding: 0.75rem 0 !important;
  text-align: center !important;
  border-radius: 0 20px !important;
  color: var(--clr-light-green) !important;
  background: var(--clr-black-shade-bg) !important;
}

.wrapper-container {
  top: 50%;
  left: 50%;
  width: 102%;
  height: 102%;
  display: flex;
  padding: 1.5rem;
  position: absolute;
  align-items: center;
  border-radius: 0 40px;
  justify-content: center;
  backdrop-filter: blur(5px);
  transform: translate(-50%, -50%);
  border: 1px solid var(--clr-black-shade-bg);
}

.pol-creater {
  width: 100%;
  height: 100%;
  border-radius: 0 40px;
  background: var(--clr-black-shade-bg);
}

.pol-creater .container {
  gap: 0.5rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.pol-creater .container input {
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border-radius: 0 30px;
  background: transparent;
  color: var(--clr-light-green);
  border: 1px solid var(--clr-light-green);
}

.pol-creater .container input::placeholder {
  font-size: 0.6rem;
  text-align: center;
  color: var(--clr-light-green);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.pol-creater .container .confirm-btn {
  padding: 0.5rem;
  font-size: 0.7rem;
  background: var(--clr-light-green);
}

@media only screen and (max-width: 998px) {
  .main-container {
    height: 100vh;
    /* margin-top: 15vh;
    margin-bottom: 10vh; */
    align-items: center;
  }

  .upper-box-group {
    gap: 2rem;
    top: -250px;
    width: max-content;
  }

  .left-box-group,
  .right-box-group {
    left: 50%;
    gap: 2rem;
    --distance: -40%;
    height: max-content;
    transform: translateX(-50%);
    grid-template-columns: 1fr 1fr;
  }

  .left-box-group {
    top: var(--distance);
    position: absolute;
  }

  .right-box-group {
    bottom: var(--distance);
    position: absolute;
  }

  .left-box-group .card:nth-child(2),
  .right-box-group .card:nth-child(2) {
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }

  .left-box-group .card:nth-child(2) {
    top: 120%;
  }

  .right-box-group .card:nth-child(2) {
    bottom: 120%;
  }

  .left-box-group .card::before,
  .right-box-group .card::before {
    left: 50%;
    width: 2px;
    height: 120px;
    translate: -50% 0;
  }

  .left-box-group .card::before {
    top: 100%;
  }

  .right-box-group .card::before {
    bottom: 100%;
  }

  /* 
          *Main mid card resposiveness
     */

  .token-container {
    padding: 5%;
  }

  .payment-option-container {
    padding: 5% 7%;
  }
}

@media screen and (max-width: 768px) {
  .left-box-group,
  .right-box-group,
  .upper-box-group {
    gap: 1rem;
  }

  .main-container {
    height: 100vh;
    margin-top: 20vh;
    margin-bottom: 10vh;
    align-items: center;
    padding: 1.5rem;
  }

  .comet-img {
    bottom: -40px;
  }

  .comet-img img {
    width: 70px;
  }
}
